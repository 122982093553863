import pathUrl from "./path"
import instance from './request'


const axios = instance;
const api = {
    // upload data =========================================================================
    getTemplateFile() {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-template-file");
    },

    // Contact Part=====================================================================
    postContactUs(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/contactus",
        });
    },

    // Data Detail Page==========================================================================
    // data drug
    getDrugInformation(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-data-drug-info/" + id);
    },
    postDrugExperiments(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/api-data-drug-experiments",
        });
    },
    getDrugTranscriptomeResponse(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-drug-transcriptome-response/" + id);
    },
    getDrugTranscriptomeSafty(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-drug-transcriptome-safty/" + id);
    },
    getEachDifferentialGene(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-each-differential-gene/" + id);
    },
    // data disease
    getDiseaseInformation(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-data-disease-info/" + id);
    },
    postDiseaseExperiments(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/api-data-disease-experiments",
        });
    },
    getDiseaseTranscriptomeResponse(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-disease-transcriptome-response/" + id);
    },
    getDiseaseTranscriptomeSafty(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-disease-transcriptome-safty/" + id);
    },

    postDrugDiseaseTranscriptome(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/api-drug-dsease-transcriptome",
        });
    },
    getDrugStatus() {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-drug-status");
    },

    // data marker
    getMarkerInformation(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-data-marker-info/" + id);
    },
    getMarkerClass(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-data-marker-class/" + id);
    },
    postMarkerExperiments(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/api-data-marker-experiments",
        });
    },
    getDataMarkerExpressionOptions(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-data-marker-expression-options/" + id);
    },
    postDataMarkerExpressionData(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/api-data-marker-expression-data",
        });
    },
    getBiomarkerTranscriptomeResponse(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-biomarker-transcriptome-response/" + id);
    },
    getBiomarkerTranscriptomeSafty(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-biomarker-transcriptome-safty/" + id);
    },
    getNonTherapeuticBiomarker(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-non-therapeutic-biomarker/" + id);
    },
    // getBoxPlotMapData() {
    //     return axios.get(`http://scprdb.idrblab.net/graph/BoxPlot/data/SCP0048/1433E_MOUSE.json`);
    // },
    getExpBoxPlotData(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-exp-box-plot/" + id);
    },
    getExpTissuePlotData(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-exp-tissue-plot/" + id);
    },
    getResponseBoxPlotData(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-response-box-plot-data/" + id);
    },

    // Search Form Page==========================================================================
    // 一级下拉
    postDiseaseClass(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/api-disease-class-list",
        });
    },
    postDrugStatusList(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/api-drug-status-list",
        });
    },
    postBiomarkerTypeList(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/api-biomarker-type-list",
        });
    },
    getBiomarkerAdrTypeList() {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-biomarker-adr-type-list");
    },
    getDrugAdrTypeList() {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-drug-adr-type-list");
    },
    // 二级下拉
    postDiseaseList(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/api-disease-list",
        });
    },
    postDrugList(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/api-drug-list",
        });
    },
    postBiomarkerList(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/api-biomarker-list",
        });
    },
    getAdrBiomarkerList(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-adr-biomarker-list/" + id);
    },
    getAdrDrugList(id) {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-adr-drug-list/" + id);
    },
    // 非二级联动下拉
    postAllDrugList(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/api-all-drug-list",
        });
    },
    getEndpointBiomarkerList() {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-endpoint-biomarker-list");
    },
    getTargetEngagementDrugClassList() {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-target-engagement-drug-class-list");
    },

    // Search Result ==========================================================================
    postDrugSearch(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/api-drug-search",
        });
    },
    postDiseaseSearch(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/api-disease-search",
        });
    },
    postBiomarkerSearch(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/api-biomarker-search",
        });
    },

    // Browse Page ==========================================================================
    getBrowsePage() {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-browse-page");
    },






    getTestData() {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-get-test-data");
    },




















}

export default api;