<template>
  <div class="main-width">
    <div class="home-fulltext">
      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p
            style="font-weight: bold; margin-left: 68px; font-size: 22px"
            class="disease-search-color"
          >
            Search for Disease Entries in Whole Database:
          </p>
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-input
            v-model="fullText"
            placeholder="Enter the keyword of Disease"
            style="width: 900px; font-size: 20px"
          >
          </el-input>
          <el-button
            class="btn-submit"
            @click="submitFullText()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
        <p style="margin-left: 68px; font-size: 18px">
          Example:
          <a
            class="disease-search-color"
            href="/search-disease?api=fullText&keyword=Prostate cancer"
            hreflang="en"
            >Prostate cancer</a
          >;
          <a
            class="disease-search-color"
            href="/search-disease?api=fullText&keyword=Breast cancer"
            hreflang="en"
            >Breast cancer</a
          >;
          <a
            class="disease-search-color"
            href="/search-disease?api=fullText&keyword=IL6"
            hreflang="en"
            >IL6</a
          >;
          <a
            class="disease-search-color"
            href="/search-disease?api=fullText&keyword=PGF"
            hreflang="en"
            >PGF</a
          >;
          <a
            class="disease-search-color"
            href="/search-disease?api=fullText&keyword=Levodopa"
            hreflang="en"
            >Levodopa</a
          >;
          <a
            class="disease-search-color"
            href="/search-disease?api=fullText&keyword=Acetaminophen"
            hreflang="en"
            >Acetaminophen</a
          >
          ...
        </p>
      </div>
    </div>

    <div
      style="
        padding: 50px;
        background-color: #d361570d;
        margin-top: 40px;
        box-shadow: rgb(126, 124, 124) 0px 0px 2px 0px;
        border: 1px solid rgb(241, 119, 0);
        border-radius: 5px;
      "
    >
      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 68px; font-size: 22px">
            Search for Disease Entries by Disease Class & Disease Name:
          </p>
        </div>
        <div>
          <p style="margin-left: 68px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the disease class first, then a list of disease name
            under the selected class will be available for selection.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-model="disease_class"
            filterable
            default-first-option
            placeholder="Step1: Please select a disease class"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in disease_class_options"
              :key="index"
              :label="item.disease_class"
              :value="item.disease_class"
            >
            </el-option>
          </el-select>

          <el-button
            class="btn-submit"
            @click="resetDiseaseList()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="check_disease_name"
            v-model="disease_name"
            filterable
            default-first-option
            placeholder="Step2: Please select a disease name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in disease_name_options"
              :key="index"
              :label="item.icd11_disease"
              :value="item.icd11_disease"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitDiseaseList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>

      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 68px; font-size: 22px">
            Search for Disease Entries by Drug Name:
          </p>
        </div>

        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="check_drug_name"
            v-model="drug_name"
            filterable
            default-first-option
            placeholder="Please select a drug name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in drug_name_options"
              :key="index"
              :label="item.drug_name"
              :value="item.drug_name"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitDrugList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>
      <div style="display: flex; flex-direction: column; margin-bottom: 10px">
        <div>
          <p style="font-weight: bold; margin-left: 68px; font-size: 22px">
            Search for Disease Entries by Biomarker Type & Biomarker Name:
          </p>
        </div>
        <div>
          <p style="margin-left: 68px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the biomarker type first, then a list of biomarker
            name under the selected type will be available for selection.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-model="biomarker_type"
            filterable
            default-first-option
            placeholder="Step1: Please select a biomarker type"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in biomarker_type_options"
              :key="index"
              :label="item.biomarker_type_class"
              :value="item.biomarker_type_class"
            >
            </el-option>
          </el-select>

          <el-button
            class="btn-submit"
            @click="resetBiomarkerList()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="check_biomarker_name"
            v-model="biomarker_name"
            filterable
            :filter-method="dataFilter"
            @visible-change="visibleChange"
            @focus="selectFocus"
            @blur="selectBlur"
            @change="selectChange"
            ref="searchSelect"
            placeholder="Step2: Please select a biomarker name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in biomarker_name_options_part"
              :key="index"
              :label="item.biomarker_name_dropdown"
              :value="item.biomarker_name_dropdown"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitBiomarkerList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
// import HomeLimitHight from "@/components/home/HomeLimitHight.vue";
import api from "../../api/index";
import pathUrl from "../../api/path";
// import pathUrl from "../../api/path";
export default {
  // name: "AdvancedAnalysis",
  data() {
    return {
      bio_num: 2000,
      localUrl: pathUrl.localUrl,
      selected_class: ["1", "2", "3", "4", "5"],

      fullText: "",
      drug_status: "",
      disease_class: "",
      biomarker_type: "",

      drug_name: "",
      disease_name: "",
      biomarker_name: "",

      drug_status_options: "",
      disease_class_options: "",
      biomarker_type_options: "",

      drug_name_options: "",
      disease_name_options: "",
      biomarker_name_options: "",
      biomarker_name_options_part: "",

      check_drug_name: false,
      check_disease_name: false,
      check_biomarker_name: false,
    };
  },
  components: {
    // HomeLimitHight,
  },

  methods: {
    dataFilter(str) {
      console.log(str);
      this.biomarker_name = str;
      var tmp_list = [];
      if (str) {
        tmp_list = this.biomarker_name_options.filter((item) => {
          if (
            item.biomarker_name_dropdown.includes(str) ||
            item.biomarker_name_dropdown
              .toUpperCase()
              .includes(str.toUpperCase())
          ) {
            return true;
          }
        });
      } else {
        tmp_list = this.biomarker_name_options;
      }
      this.biomarker_name_options_part = tmp_list.slice(0, this.bio_num);
    },
    selectFocus(e) {
      console.log(e);
      let value = e.target.value;
      setTimeout(() => {
        let input = this.$refs.searchSelect.$children[0].$refs.input;
        input.value = value;
      });
    },
    selectBlur() {
      //console.log('失去')
    },
    selectChange() {},
    visibleChange(val) {
      // console.log(val);
      if (!val) {
        let input = this.$refs.searchSelect.$children[0].$refs.input;
        console.log(input);
        input.blur();
      }
    },


    submitFullText() {
      this.$router.push(
        "/search-disease?api=fullText&keyword=" + this.fullText
      );
    },
    submitDrugList() {
      // this.$router.push(
      //   "/search-disease?api=byDrugName&group=" +
      //     this.drug_status +
      //     "&keyword=" +
      //     this.drug_name
      // );
      this.$router.push({
        path: `/search-disease`,
        query: {
          api: "byDrugName",
          keyword: this.drug_name,
        },
      });
    },
    submitDiseaseList() {
      // this.$router.push(
      //   "/search-disease?api=byDiseaseName&group=" +
      //     this.disease_class +
      //     "&keyword=" +
      //     this.disease_name
      // );
      this.$router.push({
        path: `/search-disease`,
        query: {
          api: "byDiseaseName",
          keyword: this.disease_name,
        },
      });
    },
    submitBiomarkerList() {
      // // console.log(this.biomarker_name);
      // this.$router.push(
      //   "/search-disease?api=byBiomarkerName&group=" +
      //     this.biomarker_type +
      //     "&keyword=" +
      //     this.biomarker_name.replace('+','%2B')
      // );
      this.$router.push({
        path: `/search-disease`,
        query: {
          api: "byBiomarkerName",
          // keyword: this.biomarker_name.replace("+", "%2B"),
          keyword: this.biomarker_name,
        },
      });
    },
    resetDrugList() {
      this.drug_status = "";
      this.drug_name = "";
    },
    resetDiseaseList() {
      this.disease_class = "";
      this.disease_name = "";
    },
    resetBiomarkerList() {
      this.biomarker_type = "";
      this.biomarker_name = "";
    },
  },

  beforeMount() {},
  mounted() {
    api
      .postDiseaseClass({ page_type: "disease_id", data: this.selected_class })
      .then((res) => {
        // console.log(res);
        this.disease_class_options = res.data.options;
      });
    // api.postDrugStatusList({page_type:'disease_id', data: this.selected_class }).then((res) => {
    //   // console.log(res);
    //   this.drug_status_options = res.data.options;
    // });
    this.check_drug_name = true;
    api
      .postAllDrugList({ page_type: "disease_id", data: this.selected_class })
      .then((res) => {
        this.drug_name_options = res.data.options;
        this.check_drug_name = false;
      });
    api
      .postBiomarkerTypeList({
        page_type: "disease_id",
        data: this.selected_class,
      })
      .then((res) => {
        // console.log(res);
        this.biomarker_type_options = res.data.options;
      });
  },
  watch: {
    selected_class: {
      handler: function () {
        this.fullText = "";
        this.drug_status = "";
        this.disease_class = "";
        this.biomarker_type = "";
        api
          .postDiseaseClass({
            page_type: "disease_id",
            data: this.selected_class,
          })
          .then((res) => {
            this.disease_class_options = res.data.options;
          });
        // api.postDrugStatusList({page_type:'disease_id', data: this.selected_class }).then((res) => {
        //   this.drug_status_options = res.data.options;
        // });
        this.check_drug_name = true;
        api
          .postAllDrugList({
            page_type: "disease_id",
            data: this.selected_class,
          })
          .then((res) => {
            this.drug_name_options = res.data.options;
            this.check_drug_name = false;
          });
        api
          .postBiomarkerTypeList({
            page_type: "disease_id",
            data: this.selected_class,
          })
          .then((res) => {
            this.biomarker_type_options = res.data.options;
          });
      },
      deep: true,
    },
    // drug_status: {
    //   handler: function () {
    //     this.check_drug_name = true;
    //     // console.log(o);
    //     this.drug_name = "";
    //     api
    //       .postDrugList({
    //         page_type:'disease_id',
    //         data: this.selected_class,
    //         drug_status: this.drug_status,
    //       })
    //       .then((res) => {
    //         this.check_drug_name = false;
    //         this.drug_name_options = res.data.options;
    //       });
    //   },
    //   deep: true,
    // },
    disease_class: {
      handler: function () {
        this.check_disease_name = true;
        // console.log(o);
        this.disease_name = "";
        api
          .postDiseaseList({
            page_type: "disease_id",
            data: this.selected_class,
            disease_class: this.disease_class,
          })
          .then((res) => {
            // console.log(res);
            this.check_disease_name = false;
            this.disease_name_options = res.data.options;
          });
      },
      deep: true,
    },
    biomarker_type: {
      handler: function () {
        this.check_biomarker_name = true;
        // console.log(o);
        this.biomarker_name = "";
        api
          .postBiomarkerList({
            page_type: "disease_id",
            data: this.selected_class,
            biomarker_type: this.biomarker_type,
          })
          .then((res) => {
            // console.log(res);
            this.check_biomarker_name = false;
            this.biomarker_name_options = res.data.options;
            if (this.biomarker_name_options.length > this.bio_num) {
              this.biomarker_name_options_part =
                this.biomarker_name_options.slice(0, this.bio_num);
              this.biomarker_name_options_part.push({
                biomarker_name_dropdown: "... No. of options displayed is limited, enter key characters to narrow the list of options",
                disabled: true,
              });
            } else {
              this.biomarker_name_options_part = this.biomarker_name_options;
            }
          });
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
@color-disease: #d36157;
@color-disease-hover: #d3615794;


/deep/.more_less{
  background-image: linear-gradient(to left, #fdf7f6, #f5dbd8);
  color: #d36157;
}
.each-class {
  display: flex;
  justify-content: space-between;
  border-color: #80808033;
  border-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  padding: 5px 20px;
  .dis-title {
    font-size: 20px;
    font-weight: bold;
    color: #ff9800;
  }
}
.big-plot {
  height: 700px;
  width: 1199px;
  box-shadow: 1px 1px 4px 5px #e4e8f5;
  border-radius: 6px;
  padding-top: 10px;
}
.small-plot {
  height: 400px;
  width: 587.5px;
  margin: 15px 5.6px 0 5.6px;
  box-shadow: 1px 1px 4px 5px #e4e8f5;
  border-radius: 6px;
  padding-top: 10px;
  display: inline-block;
}
.each-class:nth-child(even) {
  background: #fafafa;
}
.each-class:nth-child(odd) {
  background: #ffffff;
}
/deep/ .el-form-item__label {
  font-size: 22px;
  font-weight: bold;
  color: #455da5;
}
/deep/.el-drawer__close-btn {
  display: none;
}
/deep/.el-button {
  font-size: 24px !important;
  height: 45px;
  padding: 0 20px;
  background: @color-disease;
  border-color: @color-disease;
}
/deep/.el-progress-bar__inner {
  background-color: #66cfff;
}
/deep/.el-button:hover,
/deep/.el-button:focus {
  color: #ffffff;
  background: @color-disease-hover;
  border-color: @color-disease-hover;
  outline: 0px;
}
/deep/.el-date-editor.el-input {
  width: 100%;
}
/deep/.el-radio__label {
  font-size: 20px;
}
/deep/.el-radio__input {
  margin-top: -3.5px;
}
/deep/.el-radio {
  margin-top: 6px;
}
/deep/.el-dialog__header {
  border-bottom: 1px solid #e5e5e5;
}
/deep/.el-dialog {
  overflow-y: auto !important;
  margin: 50px auto !important;
  height: 800px !important;
}
/deep/.el-transfer__button {
  width: 110px;
  padding-bottom: 6px;
}
/deep/.el-transfer-panel {
  width: 425px;
  height: 680px;
}
/deep/.el-transfer-panel__list.is-filterable {
  height: 100%;
}
/deep/.el-transfer-panel__body {
  height: 100%;
}
/deep/input {
  height: 50px;
  padding-left: 15px;
  font-size: 20px;
  border: 2px solid @color-disease;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  outline: none;
}
/deep/.el-select:hover,
/deep/.el-range-editor.is-active,
/deep/.el-range-editor.is-active:hover,
// /deep/.el-select .el-input.is-focus .el-input__inner {
//   border-color: #c55a11 !important;
// }
/deep/.el-input__inner {
  border-color: @color-disease !important;
}
/deep/.el-button {
  font-size: 22px !important;
  height: 50px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 2px;
}
/deep/.div-title {
    color: @color-disease;
}
/deep/.div-unit{
  border-color: @color-disease;
}
/deep/.div-content{
  border-top-color: @color-disease;
}

















@color-marker1: #a7662b;
@color-marker2: #b7472a;
@color-marker3: #548235;
@color-marker4: #9c27b0;
@color-marker5: #0053d6;
.marker1-checkbox {
  /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
    color: @color-marker1;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: @color-marker1;
    border-color: @color-marker1;
  }
  /deep/.el-checkbox__inner:hover {
    border-color: @color-marker1;
  }
  /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: @color-marker1;
  }
}
.marker2-checkbox {
  /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
    color: @color-marker2;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: @color-marker2;
    border-color: @color-marker2;
  }
  /deep/.el-checkbox__inner:hover {
    border-color: @color-marker2;
  }
  /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: @color-marker2;
  }
}
.marker3-checkbox {
  /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
    color: @color-marker3;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: @color-marker3;
    border-color: @color-marker3;
  }
  /deep/.el-checkbox__inner:hover {
    border-color: @color-marker3;
  }
  /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: @color-marker3;
  }
}
.marker4-checkbox {
  /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
    color: @color-marker4;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: @color-marker4;
    border-color: @color-marker4;
  }
  /deep/.el-checkbox__inner:hover {
    border-color: @color-marker4;
  }
  /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: @color-marker4;
  }
}
.marker5-checkbox {
  /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
    color: @color-marker5;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: @color-marker5;
    border-color: @color-marker5;
  }
  /deep/.el-checkbox__inner:hover {
    border-color: @color-marker5;
  }
  /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: @color-marker5;
  }
}

</style>
  