<template>
  <div class="main-width">
    <!-- <div class="bioClass-part">
      <div class="bioClass-row">
        <div class="">
          <div
            :class="[
              'bc-div',
              'bc3-div',
              selected_class.indexOf('3') + 1 ? '' : 'deactivation',
            ]"
            @click="selected_class = ['3']"
          >
            <div class="bc-logo">
              <div style="margin: auto">
                <i class="icon iconfont icon-jimu search-page-icon"></i>
              </div>
            </div>
            <div class="bc-name">
              <div>Target Engagement <br />Biomarker</div>
            </div>
          </div>
        </div>
        <div class="">
          <div
            :class="[
              'bc-div',
              'bc4-div',
              selected_class.indexOf('4') + 1 ? '' : 'deactivation',
            ]"
            @click="selected_class = ['4']"
          >
            <div class="bc-logo">
              <div style="margin: auto">
                <i class="icon iconfont icon-xuanze search-page-icon"></i>
              </div>
            </div>
            <div class="bc-name">
              <div>Patient Selection <br />Biomarker</div>
            </div>
          </div>
        </div>

        <div class="">
          <div
            :class="[
              'bc-div',
              'bc2-div',
              selected_class.indexOf('2') + 1 ? '' : 'deactivation',
            ]"
            @click="selected_class = ['2']"
          >
            <div class="bc-logo">
              <div style="margin: auto">
                <i class="icon iconfont icon-anquan search-page-icon"></i>
              </div>
            </div>
            <div class="bc-name">
              <div>Drug Safety<br />Biomarker</div>
            </div>
          </div>
        </div>

        <div class="">
          <div
            :class="[
              'bc-div',
              'bc1-div',
              selected_class.indexOf('1') + 1 ? '' : 'deactivation',
            ]"
            @click="selected_class = ['1']"
          >
            <div class="bc-logo">
              <div style="margin: auto">
                <i class="icon iconfont icon-xiangying2 search-page-icon"></i>
              </div>
            </div>
            <div class="bc-name">
              <div>Treatment Response <br />Biomarker</div>
            </div>
          </div>
        </div>

        <div class="">
          <div
            :class="[
              'bc-div',
              'bc5-div',
              selected_class.indexOf('5') + 1 ? '' : 'deactivation',
            ]"
            @click="selected_class = ['5']"
          >
            <div class="bc-logo">
              <div style="margin: auto">
                <i class="icon iconfont icon-zhongdian search-page-icon"></i>
              </div>
            </div>
            <div class="bc-name">
              <div>Clinical Surrogate <br />Endpoint</div>
            </div>
          </div>
        </div>
      </div>
      <p>
        There are 5 therapeutic biomarker classes. You can select a single
        biomarker class by clicking on the corresponding icon above, or select
        one or more biomarker class(es) by clicking on the multi-select box
        below.
      </p>
      <p>The currently biomarker class(es) search scope includes:</p>
      <div style="padding-left: 10px">
        <el-checkbox-group v-model="selected_class">
          <div style="">
            <el-checkbox label="3" class="marker3-checkbox" style="width: 330px"
              >Target Engagement Biomarker</el-checkbox
            >
            <el-checkbox label="4" class="marker4-checkbox" style="width: 330px"
              >Patient Selection Biomarker</el-checkbox
            >

            <el-checkbox label="2" class="marker2-checkbox" style="width: 330px"
              >Drug Safety Biomarker</el-checkbox
            >
          </div>
          <div>
            <el-checkbox label="1" class="marker1-checkbox" style="width: 330px"
              >Treatment Response Biomarker</el-checkbox
            >
            <el-checkbox label="5" class="marker5-checkbox" style="width: 330px"
              >Clinical Surrogate Endpoint</el-checkbox
            >
          </div>
        </el-checkbox-group>
      </div>
    </div> -->
    <div class="home-fulltext">
      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p
            style="font-weight: bold; margin-left: 68px; font-size: 22px"
            class="biomarker-search-color"
          >
            Search for {{ num2BiomarkerClass[select_radio] }} Entries in Whole
            Database:
          </p>
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-input
            v-model="fullText"
            placeholder="Enter the keyword of Biomarker"
            style="width: 900px; font-size: 20px"
          >
          </el-input>
          <el-button
            class="btn-submit"
            @click="submitFullText()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
        <p
          v-if="select_radio == '1,2,3,4,5'"
          style="margin-left: 68px; font-size: 18px"
        >
          Example:
          <a
            href="/search-marker?api=fullText&keyword=PDL1&biomarker_class=1,2,3,4,5"
            hreflang="en"
            class="biomarker-search-color"
            >PDL1</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=CRP&biomarker_class=1,2,3,4,5"
            hreflang="en"
            class="biomarker-search-color"
            >CRP</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=Bevacizumab&biomarker_class=1,2,3,4,5"
            hreflang="en"
            class="biomarker-search-color"
            >Bevacizumab</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=Cisplatin&biomarker_class=1,2,3,4,5"
            hreflang="en"
            class="biomarker-search-color"
            >Cisplatin</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=Breast cancer&biomarker_class=1,2,3,4,5"
            hreflang="en"
            class="biomarker-search-color"
            >Breast cancer</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=Multiple sclerosis&biomarker_class=1,2,3,4,5"
            hreflang="en"
            class="biomarker-search-color"
            >Multiple sclerosis</a
          >
          ...
        </p>
        <p
          v-else-if="select_radio == '3'"
          style="margin-left: 68px; font-size: 18px"
        >
          Example:
          <a
            href="/search-marker?api=fullText&keyword=Alzheimer disease&biomarker_class=3"
            hreflang="en"
            class="biomarker-search-color"
            >Alzheimer disease</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=Leflunomide&biomarker_class=3"
            hreflang="en"
            class="biomarker-search-color"
            >Leflunomide</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=Wee1 inhibitor&biomarker_class=3"
            hreflang="en"
            class="biomarker-search-color"
            >Wee1 inhibitor</a
          >
          ...
        </p>
        <p
          v-else-if="select_radio == '4'"
          style="margin-left: 68px; font-size: 18px"
        >
          Example:
          <a
            href="/search-marker?api=fullText&keyword=BCR-ABL mRNA&biomarker_class=4"
            hreflang="en"
            class="biomarker-search-color"
            >BCR-ABL mRNA</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=CRP&biomarker_class=4"
            hreflang="en"
            class="biomarker-search-color"
            >CRP</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=Nilotinib&biomarker_class=4"
            hreflang="en"
            class="biomarker-search-color"
            >Nilotinib</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=Chronic myeloid leukemia&biomarker_class=4"
            hreflang="en"
            class="biomarker-search-color"
            >Chronic myeloid leukemia</a
          >
          ...
        </p>
        <p
          v-else-if="select_radio == '2'"
          style="margin-left: 68px; font-size: 18px"
        >
          Example:
          <a
            href="/search-marker?api=fullText&keyword=CRP&biomarker_class=2"
            hreflang="en"
            class="biomarker-search-color"
            >CRP</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=HLA&biomarker_class=2"
            hreflang="en"
            class="biomarker-search-color"
            >HLA</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=Hepatotoxicity&biomarker_class=2"
            hreflang="en"
            class="biomarker-search-color"
            >Hepatotoxicity</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=Levodopa&biomarker_class=2"
            hreflang="en"
            class="biomarker-search-color"
            >Levodopa</a
          >
          ...
        </p>
        <p
          v-else-if="select_radio == '1'"
          style="margin-left: 68px; font-size: 18px"
        >
          Example:
          <a
            href="/search-marker?api=fullText&keyword=PDL1&biomarker_class=1"
            hreflang="en"
            class="biomarker-search-color"
            >PDL1</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=Psoriasis&biomarker_class=1"
            hreflang="en"
            class="biomarker-search-color"
            >Psoriasis</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=Ipilimumab&biomarker_class=1"
            hreflang="en"
            class="biomarker-search-color"
            >Ipilimumab</a
          >
          ...
        </p>
        <p
          v-else-if="select_radio == '5'"
          style="margin-left: 68px; font-size: 18px"
        >
          Example:
          <a
            href="/search-marker?api=fullText&keyword=Durable objective overall response rate&biomarker_class=5"
            hreflang="en"
            class="biomarker-search-color"
            >Durable objective overall response rate</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=Influenza&biomarker_class=5"
            hreflang="en"
            class="biomarker-search-color"
            >Influenza</a
          >;
          <a
            href="/search-marker?api=fullText&keyword=Nivolumab&biomarker_class=5"
            hreflang="en"
            class="biomarker-search-color"
            >Nivolumab</a
          >
          ...
        </p>
      </div>
    </div>

    <div class="bioClass-part">
      <div class="bioClass-row">


        <div class="">
          <div
            :class="[
              'bc-div',
              'bc3-div',
              select_radio.indexOf('3') !== -1 ? '' : 'deactivation',
            ]"
            @click="select_radio = '3'"
          >
            <div class="bc-logo">
              <div style="margin: auto">
                <i class="icon iconfont icon-jimu search-page-icon"></i>
              </div>
            </div>
            <div class="bc-name">
              <div>
                Pharmacodynamic <br />
                Biomarker (PDY)
              </div>
            </div>
          </div>
        </div>


        <div class="">
          <div
            :class="[
              'bc-div',
              'bc2-div',
              select_radio.indexOf('2') !== -1 ? '' : 'deactivation',
            ]"
            @click="select_radio = '2'"
          >
            <div class="bc-logo">
              <div style="margin: auto">
                <i class="icon iconfont icon-anquan search-page-icon"></i>
              </div>
            </div>
            <div class="bc-name">
              <div>Safety <br />Biomarker (SAF)</div>
            </div>
          </div>
        </div>

        <div class="">
          <div
            :class="[
              'bc-div',
              'bc4-div',
              select_radio.indexOf('4') !== -1 ? '' : 'deactivation',
            ]"
            @click="select_radio = '4'"
          >
            <div class="bc-logo">
              <div style="margin: auto">
                <i class="icon iconfont icon-xuanze search-page-icon"></i>
              </div>
            </div>
            <div class="bc-name">
              <div>Monitoring <br />Biomarker (MOI)</div>
            </div>
          </div>
        </div>
        <div class="">
          <div
            :class="[
              'bc-div',
              'bc1-div',
              select_radio.indexOf('1') !== -1 ? '' : 'deactivation',
            ]"
            @click="select_radio = '1'"
          >
            <div class="bc-logo">
              <div style="margin: auto">
                <i class="icon iconfont icon-xiangying2 search-page-icon"></i>
              </div>
            </div>
            <div class="bc-name">
              <div>Predictive <br />Biomarker (PRD)</div>
            </div>
          </div>
        </div>
        <div class="">
          <div
            :class="[
              'bc-div',
              'bc5-div',
              select_radio.indexOf('5') !== -1 ? '' : 'deactivation',
            ]"
            @click="select_radio = '5'"
          >
            <div class="bc-logo">
              <div style="margin: auto">
                <i class="icon iconfont icon-zhongdian search-page-icon"></i>
              </div>
            </div>
            <div class="bc-name">
              <div>Surrogate <br />Endpoint (SUR)</div>
            </div>
          </div>
        </div>
      </div>
      <p>
        A total of 5 types of therapeutic biomarker (ThMAR) are provided in
        TheMarker, the detailed description of which can be found
        <a @click="dialogVisible = true" style="cursor: pointer">HERE </a>.
      </p>
      <p>
        You can select any type of ThMAR or all five types of ThMAR by clicking
        on the corresponding icon above, or select radio box below.
      </p>
      <p>The current search scope of ThMAR is:</p>
      <!-- <div style="padding-left: 10px">
        <el-checkbox-group v-model="selected_class">
          <div style="">
            <el-checkbox label="3" class="marker3-checkbox" style="width: 330px"
              >Target Engagement Biomarker</el-checkbox
            >
            <el-checkbox label="4" class="marker4-checkbox" style="width: 330px"
              >Patient Selection Biomarker</el-checkbox
            >

            <el-checkbox label="2" class="marker2-checkbox" style="width: 330px"
              >Drug Safety Biomarker</el-checkbox
            >
          </div>
          <div>
            <el-checkbox label="1" class="marker1-checkbox" style="width: 330px"
              >Treatment Response Biomarker</el-checkbox
            >
            <el-checkbox label="5" class="marker5-checkbox" style="width: 330px"
              >Clinical Surrogate Endpoint</el-checkbox
            >
          </div>
        </el-checkbox-group>
      </div> -->

      <div style="margin-left: 20px">
        <el-radio-group v-model="select_radio">
          <div style="margin-bottom: 10px">
            <el-radio
              :label="'1,2,3,4,5'"
              class="marker-all-checkbox"
              style="width: 330px"
              >All Biomarker Types</el-radio
            >
            <el-radio :label="'3'" class="marker3-checkbox" style="width: 390px"
              >Pharmacodynamic Biomarker (PDY)</el-radio
            >

            <el-radio :label="'2'" class="marker2-checkbox" style="width: 330px"
              >Safety Biomarker (SAF)</el-radio
            >

          </div>
          <div>

            <el-radio :label="'4'" class="marker4-checkbox" style="width: 330px"
              >Monitoring Biomarker (MOI)</el-radio
            >
            <el-radio :label="'1'" class="marker1-checkbox" style="width: 390px"
              >Predictive Biomarker (PRD)</el-radio
            >
            <el-radio :label="'5'" class="marker5-checkbox" style="width: 330px"
              >Surrogate Endpoint (SUR)</el-radio
            >
          </div>
        </el-radio-group>
      </div>
    </div>

    <el-dialog
      title="Explanation of 5 types of ThMAR"
      :visible.sync="dialogVisible"
    >
      <img
        :src="`${localUrl}/files/home-picture/TheMarker-Figure.png`"
        style="display: initial; width: 100%"
      />
    </el-dialog>

    <div
      style="
        padding: 50px;
        background-color: #ee77000d;
        box-shadow: #7e7c7c 0px 0px 2px 0px;
        border: 1px solid #f17700;
        border-radius: 5px;
      "
    >
      <div
        v-if="select_radio == '2'"
        style="display: flex; flex-direction: column; margin-bottom: 50px"
      >
        <div>
          <p style="font-weight: bold; margin-left: 68px; font-size: 22px">
            Search for {{ num2BiomarkerClass[select_radio] }} Entries by
            Toxicity Type & Drug Name:
          </p>
        </div>
        <div>
          <p style="margin-left: 68px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the toxicity type first, then a list of drug name
            under the selected type will be available for selection.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-model="drug_adr_type"
            filterable
            default-first-option
            placeholder="Step1: Please select a toxicity type"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in drug_adr_type_options"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>

          <el-button
            class="btn-submit"
            @click="resetDrugAdrList()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="check_drug_adr"
            v-model="drug_adr"
            filterable
            default-first-option
            placeholder="Step2: Please select a drug name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in drug_adr_options"
              :key="index"
              :label="item.drug_name"
              :value="item.drug_name"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitDrugAdrList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>
      <div
        v-if="select_radio == '2'"
        style="display: flex; flex-direction: column; margin-bottom: 50px"
      >
        <div>
          <p style="font-weight: bold; margin-left: 68px; font-size: 22px">
            Search for {{ num2BiomarkerClass[select_radio] }} Entries by
            Toxicity Type & Biomarker Name:
          </p>
        </div>
        <div>
          <p style="margin-left: 68px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the toxicity type first, then a list of biomarker name
            under the selected type will be available for selection.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-model="biomarker_adr_type"
            filterable
            default-first-option
            placeholder="Step1: Please select a toxicity type"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in biomarker_adr_type_options"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>

          <el-button
            class="btn-submit"
            @click="resetBiomarkerAdrList()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="check_biomarker_adr"
            v-model="biomarker_adr"
            filterable
            default-first-option
            placeholder="Step2: Please select a biomarker name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in biomarker_adr_options"
              :key="index"
              :label="item.biomarker_name_dropdown"
              :value="item.biomarker_name_dropdown"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitBiomarkerAdrList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>

      <div
        v-if="select_radio == '5'"
        style="display: flex; flex-direction: column; margin-bottom: 50px"
      >
        <div>
          <p style="font-weight: bold; margin-left: 68px; font-size: 22px">
            Search for {{ num2BiomarkerClass[select_radio] }} Entries by
            Biomarker Name:
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="check_biomarker_name"
            v-model="biomarker_name"
            filterable
            default-first-option
            placeholder="Please select a surrogate endpoint"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in endpoint_biomarker_options"
              :key="index"
              :label="item.biomarker_name_dropdown"
              :value="item.biomarker_name_dropdown"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitEndpointBiomarkerList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>

      <!-- <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 68px; font-size: 22px">
            Search for Biomarker Entries by Drug Name:
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="check_drug_name"
            v-model="drug_name"
            filterable
            default-first-option
            placeholder="Step1: Please select a drug name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in drug_name_options"
              :key="index"
              :label="item.drug_name"
              :value="item.drug_name"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitDrugList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div> -->
      <div
        v-if="(select_radio != '2') & (select_radio != '5')"
        style="display: flex; flex-direction: column; margin-bottom: 50px"
      >
        <div>
          <p style="font-weight: bold; margin-left: 68px; font-size: 22px">
            Search for {{ num2BiomarkerClass[select_radio] }} Entries by
            Biomarker Type & Biomarker Name:
          </p>
        </div>
        <div>
          <p style="margin-left: 68px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the biomarker type first, then a list of biomarker
            name under the selected type will be available for selection.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-model="biomarker_type"
            filterable
            default-first-option
            placeholder="Step1: Please select a biomarker type"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in biomarker_type_options"
              :key="index"
              :label="item.biomarker_type_class"
              :value="item.biomarker_type_class"
            >
            </el-option>
          </el-select>

          <el-button
            class="btn-submit"
            @click="resetBiomarkerList()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="check_biomarker_name"
            v-model="biomarker_name"
            filterable
            :filter-method="dataFilter"
            @visible-change="visibleChange"
            @focus="selectFocus"
            @blur="selectBlur"
            @change="selectChange"
            ref="searchSelect"
            placeholder="Step2: Please select a biomarker name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in biomarker_name_options_part"
              :key="index"
              :label="item.biomarker_name_dropdown"
              :value="item.biomarker_name_dropdown"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitBiomarkerList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>
      <!-- <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 68px; font-size: 22px">
            Search for Biomarker Entries by Drug Status & Drug Name:
          </p>
        </div>
        <div>
          <p style="margin-left: 68px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the drug status first, then a list of drug name under
            the selected status will be available for selection.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-model="drug_status"
            filterable
            default-first-option
            placeholder="Step1: Please select a drug status"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in drug_status_options"
              :key="index"
              :label="item.drug_status_class"
              :value="item.drug_status_class"
            >
            </el-option>
          </el-select>

          <el-button
            class="btn-submit"
            @click="resetDrugList()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="check_drug_name"
            v-model="drug_name"
            filterable
            default-first-option
            placeholder="Step1: Please select a drug name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in drug_name_options"
              :key="index"
              :label="item.drug_name"
              :value="item.drug_name"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitDrugList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div> -->
      <div
        v-if="select_radio != '2'"
        style="display: flex; flex-direction: column; margin-bottom: 50px"
      >
        <div>
          <p style="font-weight: bold; margin-left: 68px; font-size: 22px">
            Search for {{ num2BiomarkerClass[select_radio] }} Entries by Drug
            Name:
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="check_drug_name"
            v-model="drug_name"
            filterable
            default-first-option
            placeholder="Please select a drug name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in drug_name_options"
              :key="index"
              :label="item.drug_name"
              :value="item.drug_name"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitDrugList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>
      <div style="display: flex; flex-direction: column; margin-bottom: 10px">
        <div>
          <p style="font-weight: bold; margin-left: 68px; font-size: 22px">
            Search for {{ num2BiomarkerClass[select_radio] }} Entries by Disease
            Class & Disease Name:
          </p>
        </div>
        <div>
          <p style="margin-left: 68px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the disease class first, then a list of disease name
            under the selected class will be available for selection.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-model="disease_class"
            filterable
            default-first-option
            placeholder="Step1: Please select a disease class"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in disease_class_options"
              :key="index"
              :label="item.disease_class"
              :value="item.disease_class"
            >
            </el-option>
          </el-select>

          <el-button
            class="btn-submit"
            @click="resetDiseaseList()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="check_disease_name"
            v-model="disease_name"
            filterable
            default-first-option
            placeholder="Step2: Please select a disease name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in disease_name_options"
              :key="index"
              :label="item.icd11_disease"
              :value="item.icd11_disease"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitDiseaseList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>
      <!-- <div
        v-if="selected_class.length == 5"
        style="display: flex; flex-direction: column; margin-bottom: 50px"
      >
        <div>
          <p style="font-weight: bold; margin-left: 68px; font-size: 22px">
            Search for Drug Safety Biomarker Entries by Adverse Drug Reaction
            (ADR):
          </p>
        </div>
        <div>
          <p style="margin-left: 68px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the ADR type first, then a list of ADR under the
            selected type will be available for selection.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-model="adr_type"
            filterable
            default-first-option
            placeholder="Step1: Please select an ADR type"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in adr_type_options"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>

          <el-button
            class="btn-submit"
            @click="resetAdrList()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="check_adr"
            v-model="adr"
            filterable
            default-first-option
            placeholder="Step2: Please select an ADR"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in adr_options"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitAdrList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div> -->
    </div>
  </div>
</template>
  
  <script>
// import HomeLimitHight from "@/components/home/HomeLimitHight.vue";
import api from "../../api/index";
import pathUrl from "../../api/path";
// import pathUrl from "../../api/path";
export default {
  // name: "AdvancedAnalysis",
  data() {
    return {
      // value:'',
      bio_num: 2000,
      dialogVisible: false,
      localUrl: pathUrl.localUrl,
      selected_class: ["1", "2", "3", "4", "5"],
      select_radio: "1,2,3,4,5",

      num2BiomarkerClass: {
        1: "Predictive Biomarker (PRD)",
        2: "Safety Biomarker (SAF)",
        3: "Pharmacodynamic Biomarker (PDY)",
        4: "Monitoring Biomarker (MOI)",
        5: "Surrogate Endpoint (SUR)",
        "1,2,3,4,5": "Biomarker",
      },

      fullText: "",
      drug_status: "",
      disease_class: "",
      biomarker_type: "",
      biomarker_adr_type: "",
      drug_adr_type: "",

      drug_name: "",
      disease_name: "",
      biomarker_name: "",
      biomarker_adr: "",
      drug_adr: "",
      endpoint_biomarker: "",

      drug_status_options: "",
      disease_class_options: "",
      biomarker_type_options: "",
      biomarker_adr_type_options: "",
      drug_adr_type_options: "",

      drug_name_options: "",
      disease_name_options: "",
      biomarker_name_options: "",
      biomarker_name_options_part: "",
      biomarker_adr_options: "",
      drug_adr_options: "",
      endpoint_biomarker_options: "",

      check_drug_name: false,
      check_disease_name: false,
      check_biomarker_name: false,
      check_drug_adr: false,
      check_biomarker_adr: false,
    };
  },
  components: {
    // HomeLimitHight,
  },

  methods: {
    // 对输入字符串控制
    // filterData() {
    //   var str = this.$refs.searchSelect.$data.selectedLabel; // 此属性得到输入的文字
    //   console.log(str);
    //   this.biomarker_name_options_part = this.biomarker_name_options.filter((v) => v.biomarker_name_dropdown == 'A1BG mRNA' ).slice(0, this.bio_num);
    //   console.log(this.$refs.searchSelect.$data );
    //   console.log(this.biomarker_name_options_part );
    //   // this.biomarker_name_options_part = this.biomarker_name_options.slice(0, this.bio_num);

    // },
    dataFilter(str) {
      console.log(str);
      this.biomarker_name = str;
      var tmp_list = [];
      if (str) {
        tmp_list = this.biomarker_name_options.filter((item) => {
          if (
            item.biomarker_name_dropdown.includes(str) ||
            item.biomarker_name_dropdown
              .toUpperCase()
              .includes(str.toUpperCase())
          ) {
            return true;
          }
        });
      } else {
        tmp_list = this.biomarker_name_options;
      }
      this.biomarker_name_options_part = tmp_list.slice(0, this.bio_num);
    },
    selectFocus(e) {
      console.log(e);
      let value = e.target.value;
      setTimeout(() => {
        let input = this.$refs.searchSelect.$children[0].$refs.input;
        input.value = value;
      });
    },
    selectBlur() {
      //console.log('失去')
    },
    selectChange() {},
    visibleChange(val) {
      // console.log(val);
      if (!val) {
        let input = this.$refs.searchSelect.$children[0].$refs.input;
        console.log(input);
        input.blur();
      }
    },
    submitFullText() {
      this.$router.push({
        path: `/search-marker`,
        query: {
          api: "fullText",
          keyword: this.fullText,
          biomarker_class: this.select_radio,
        },
      });
    },
    submitDrugList() {
      this.$router.push({
        path: `/search-marker`,
        query: {
          api: "byDrugName",
          group: this.drug_status,
          keyword: this.drug_name,
          biomarker_class: this.select_radio,
        },
      });
    },
    submitDiseaseList() {
      this.$router.push({
        path: `/search-marker`,
        query: {
          api: "byDiseaseName",
          group: this.disease_class,
          keyword: this.disease_name,
          biomarker_class: this.select_radio,
        },
      });
    },
    submitBiomarkerList() {
      this.$router.push({
        path: `/search-marker`,
        query: {
          api: "byBiomarkerName",
          group: this.biomarker_type,
          // keyword: this.biomarker_name.replace("+", "%2B"),
          keyword: this.biomarker_name,
          biomarker_class: this.select_radio,
        },
      });
    },
    submitDrugAdrList() {
      this.$router.push({
        path: `/search-marker`,
        query: {
          api: "byDrugName",
          group: this.drug_adr_type,
          keyword: this.drug_adr,
          biomarker_class: this.select_radio,
        },
      });
    },
    submitBiomarkerAdrList() {
      this.$router.push({
        path: `/search-marker`,
        query: {
          api: "byBiomarkerName",
          group: this.biomarker_adr_type,
          keyword: this.biomarker_adr,
          biomarker_class: this.select_radio,
        },
      });
    },
    submitEndpointBiomarkerList() {
      this.$router.push({
        path: `/search-marker`,
        query: {
          api: "byBiomarkerName",
          group: "endpoint biomarker",
          keyword: this.biomarker_name,
          biomarker_class: this.select_radio,
        },
      });
    },
    resetDrugList() {
      this.drug_status = "";
      this.drug_name = "";
    },
    resetDiseaseList() {
      this.disease_class = "";
      this.disease_name = "";
    },
    resetBiomarkerList() {
      this.biomarker_type = "";
      this.biomarker_name = "";
    },
    resetDrugAdrList() {
      this.drug_adr_type = "";
      this.drug_adr = "";
    },
    resetBiomarkerAdrList() {
      this.biomarker_adr_type = "";
      this.biomarker_adr = "";
    },
  },

  beforeMount() {},
  mounted() {
    api
      .postDiseaseClass({
        page_type: "biomarker_id",
        data: this.selected_class,
      })
      .then((res) => {
        // console.log(res);
        this.disease_class_options = res.data.options;
      });
    // api
    //   .postDrugStatusList({
    //     page_type: "biomarker_id",
    //     data: this.selected_class,
    //   })
    //   .then((res) => {
    //     // console.log(res);
    //     this.drug_status_options = res.data.options;
    //   });
    this.check_drug_name = true;
    api
      .postAllDrugList({ page_type: "biomarker_id", data: this.selected_class })
      .then((res) => {
        this.drug_name_options = res.data.options;
        this.check_drug_name = false;
      });
    api
      .postBiomarkerTypeList({
        page_type: "biomarker_id",
        data: this.selected_class,
      })
      .then((res) => {
        // console.log(res);
        this.biomarker_type_options = res.data.options;
      });

    api
      .getBiomarkerAdrTypeList({
        page_type: "biomarker_id",
        data: this.selected_class,
      })
      .then((res) => {
        // console.log(res);
        this.biomarker_adr_type_options = res.data.options;
      });
    api.getDrugAdrTypeList().then((res) => {
      // console.log(res);
      this.drug_adr_type_options = res.data.options;
    });
    api.getEndpointBiomarkerList().then((res) => {
      // console.log(res);
      this.endpoint_biomarker_options = res.data.options;
    });
  },
  watch: {
    // select_radio: {
    //   handler: function () {
    //     this.selected_class = this.select_radio.split(',');
    //   },
    //   deep: true,
    // },
    select_radio: {
      handler: function () {
        this.selected_class = this.select_radio.split(",");

        this.fullText = "";
        // this.drug_status = "";
        this.drug_name = "";
        this.disease_class = "";
        this.biomarker_type = "";
        this.biomarker_adr_type = "";
        this.drug_adr_type = "";
        this.endpoint_biomarker = "";

        api
          .postDiseaseClass({
            page_type: "biomarker_id",
            data: this.selected_class,
          })
          .then((res) => {
            this.disease_class_options = res.data.options;
          });
        // api
        //   .postDrugStatusList({
        //     page_type: "biomarker_id",
        //     data: this.selected_class,
        //   })
        //   .then((res) => {
        //     this.drug_status_options = res.data.options;
        //   });
        this.check_drug_name = true;
        api
          .postAllDrugList({
            page_type: "biomarker_id",
            data: this.selected_class,
          })
          .then((res) => {
            this.drug_name_options = res.data.options;
            this.check_drug_name = false;
          });
        api
          .postBiomarkerTypeList({
            page_type: "biomarker_id",
            data: this.selected_class,
          })
          .then((res) => {
            this.biomarker_type_options = res.data.options;
          });
        // api
        //   .getBiomarkerAdrTypeList({
        //     page_type: "biomarker_id",
        //     data: this.selected_class,
        //   })
        //   .then((res) => {
        //     // console.log(res);
        //     this.biomarker_adr_type_options = res.data.options;
        //   });
        api
          .getBiomarkerAdrTypeList({
            page_type: "biomarker_id",
            data: this.selected_class,
          })
          .then((res) => {
            // console.log(res);
            this.biomarker_adr_type_options = res.data.options;
          });
        api.getDrugAdrTypeList().then((res) => {
          // console.log(res);
          this.drug_adr_type_options = res.data.options;
        });
        this.check_biomarker_name = true;
        api.getEndpointBiomarkerList().then((res) => {
          // console.log(res);
          this.endpoint_biomarker_options = res.data.options;
          this.check_biomarker_name = false;
        });
      },
      deep: true,
    },
    // drug_status: {
    //   handler: function () {
    //     this.check_drug_name = true;
    //     // console.log(o);
    //     this.drug_name = "";
    //     api
    //       .postDrugList({
    //         page_type: "biomarker_id",
    //         data: this.selected_class,
    //         drug_status: this.drug_status,
    //       })
    //       .then((res) => {
    //         this.check_drug_name = false;
    //         this.drug_name_options = res.data.options;
    //       });
    //   },
    //   deep: true,
    // },
    disease_class: {
      handler: function () {
        this.check_disease_name = true;
        // console.log(o);
        this.disease_name = "";
        api
          .postDiseaseList({
            page_type: "biomarker_id",
            data: this.selected_class,
            disease_class: this.disease_class,
          })
          .then((res) => {
            // console.log(res);
            this.check_disease_name = false;
            this.disease_name_options = res.data.options;
          });
      },
      deep: true,
    },
    biomarker_type: {
      handler: function () {
        this.check_biomarker_name = true;
        // console.log(o);
        this.biomarker_name = "";
        api
          .postBiomarkerList({
            page_type: "biomarker_id",
            data: this.selected_class,
            biomarker_type: this.biomarker_type,
          })
          .then((res) => {
            // console.log(res);
            this.check_biomarker_name = false;
            this.biomarker_name_options = res.data.options;
            if (this.biomarker_name_options.length > this.bio_num) {
              this.biomarker_name_options_part =
                this.biomarker_name_options.slice(0, this.bio_num);
              this.biomarker_name_options_part.push({
                biomarker_name_dropdown:
                  "... No. of options displayed is limited, enter key characters to narrow the list of options",
                disabled: true,
              });
            } else {
              this.biomarker_name_options_part = this.biomarker_name_options;
            }
          });
      },
      deep: true,
    },
    drug_adr_type: {
      handler: function (o) {
        this.check_drug_adr = true;
        // console.log(o);
        this.drug_adr = "";
        api.getAdrDrugList(o).then((res) => {
          // console.log(res.data.options);
          this.check_drug_adr = false;
          this.drug_adr_options = res.data.options;
        });
      },
      deep: true,
    },
    biomarker_adr_type: {
      handler: function (o) {
        this.check_biomarker_adr = true;
        // console.log(o);
        this.biomarker_adr = "";
        api.getAdrBiomarkerList(o).then((res) => {
          // console.log(res.data.options);
          this.check_biomarker_adr = false;
          this.biomarker_adr_options = res.data.options;
        });
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
@color-marker: #ee7700;
@color-marker-hover: #ee770094;

/deep/.more_less {
  background-image: linear-gradient(to left, #fef8f2, #fad6b2);
  color: #ee7700;
}
.each-class {
  display: flex;
  justify-content: space-between;
  border-color: #80808033;
  border-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  padding: 5px 20px;
  .dis-title {
    font-size: 20px;
    font-weight: bold;
    color: #ff9800;
  }
}
.big-plot {
  height: 700px;
  width: 1199px;
  box-shadow: 1px 1px 4px 5px #e4e8f5;
  border-radius: 6px;
  padding-top: 10px;
}
.small-plot {
  height: 400px;
  width: 587.5px;
  margin: 15px 5.6px 0 5.6px;
  box-shadow: 1px 1px 4px 5px #e4e8f5;
  border-radius: 6px;
  padding-top: 10px;
  display: inline-block;
}
.each-class:nth-child(even) {
  background: #fafafa;
}
.each-class:nth-child(odd) {
  background: #ffffff;
}
/deep/ .el-form-item__label {
  font-size: 22px;
  font-weight: bold;
  color: #455da5;
}
/deep/.el-drawer__close-btn {
  display: none;
}
/deep/.el-button {
  font-size: 24px !important;
  height: 45px;
  padding: 0 20px;
  background: @color-marker;
  border-color: @color-marker;
}
/deep/.el-progress-bar__inner {
  background-color: #66cfff;
}
/deep/.el-button:hover,
/deep/.el-button:focus {
  color: #ffffff;
  background: @color-marker-hover;
  border-color: @color-marker-hover;
  outline: 0px;
}
/deep/.el-date-editor.el-input {
  width: 100%;
}
/deep/.el-radio__label {
  font-size: 20px;
}
/deep/.el-radio__input {
  margin-top: -3.5px;
}
/deep/.el-radio {
  margin-top: 6px;
}
/deep/.el-dialog__header {
  border-bottom: 1px solid #e5e5e5;
}
/deep/.el-dialog {
  overflow-y: auto !important;
  margin: 50px auto !important;
  width: 1200px;
}
/deep/.el-dialog__title {
  font-size: 24px;
  font-weight: bold;
}
/deep/.el-transfer__button {
  width: 110px;
  padding-bottom: 6px;
}
/deep/.el-transfer-panel {
  width: 425px;
  height: 680px;
}
/deep/.el-transfer-panel__list.is-filterable {
  height: 100%;
}
/deep/.el-transfer-panel__body {
  height: 100%;
}
/deep/input {
  height: 50px;
  padding-left: 15px;
  font-size: 20px;
  border: 2px solid @color-marker;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  outline: none;
}
/deep/.el-select:hover,
/deep/.el-range-editor.is-active,
/deep/.el-range-editor.is-active:hover,
/deep/.el-input__inner {
  border-color: @color-marker!important;
}
/deep/.el-button {
  font-size: 22px !important;
  height: 50px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 2px;
}
/deep/.div-title {
  color: @color-marker;
}
/deep/.div-unit {
  border-color: @color-marker;
}
/deep/.div-content {
  border-top-color: @color-marker;
}

@color-marker1: #a7662b;
@color-marker2: #b7472a;
@color-marker3: #548235;
@color-marker4: #9c27b0;
@color-marker5: #0053d6;
@color-marker-all: #ee7700;
.marker1-checkbox {
  /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
    color: @color-marker1;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: @color-marker1;
    border-color: @color-marker1;
  }
  /deep/.el-checkbox__inner:hover {
    border-color: @color-marker1;
  }
  /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: @color-marker1;
  }
}
.marker2-checkbox {
  /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
    color: @color-marker2;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: @color-marker2;
    border-color: @color-marker2;
  }
  /deep/.el-checkbox__inner:hover {
    border-color: @color-marker2;
  }
  /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: @color-marker2;
  }
}
.marker3-checkbox {
  /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
    color: @color-marker3;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: @color-marker3;
    border-color: @color-marker3;
  }
  /deep/.el-checkbox__inner:hover {
    border-color: @color-marker3;
  }
  /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: @color-marker3;
  }
}
.marker4-checkbox {
  /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
    color: @color-marker4;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: @color-marker4;
    border-color: @color-marker4;
  }
  /deep/.el-checkbox__inner:hover {
    border-color: @color-marker4;
  }
  /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: @color-marker4;
  }
}
.marker5-checkbox {
  /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
    color: @color-marker5;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: @color-marker5;
    border-color: @color-marker5;
  }
  /deep/.el-checkbox__inner:hover {
    border-color: @color-marker5;
  }
  /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: @color-marker5;
  }
}

.marker1-checkbox {
  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: @color-marker1;
  }
  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: @color-marker1;
    border-color: @color-marker1;
  }
}
.marker2-checkbox {
  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: @color-marker2;
  }
  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: @color-marker2;
    border-color: @color-marker2;
  }
}
.marker3-checkbox {
  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: @color-marker3;
  }
  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: @color-marker3;
    border-color: @color-marker3;
  }
}
.marker4-checkbox {
  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: @color-marker4;
  }
  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: @color-marker4;
    border-color: @color-marker4;
  }
}
.marker5-checkbox {
  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: @color-marker5;
  }
  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: @color-marker5;
    border-color: @color-marker5;
  }
}
.marker-all-checkbox {
  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: @color-marker-all;
  }
  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: @color-marker-all;
    border-color: @color-marker-all;
  }
}

// .bioClass-part {
//   box-shadow: rgb(126, 124, 124) 0px 0px 2px 0px;
//   border: 1px solid #f17700;
// }
</style>
  