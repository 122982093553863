import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'TheMarker: A Comprehensive Database of Therapeutic Biomarkers'
    }
  },
  {
    path: '/update-data-SR',
    name: 'update-data-SR',
    component: () => import(/* webpackChunkName: "about" */ '../views/UpdateDate.vue'),
    meta: {
      title: 'Update Data | TheMarker'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue'),
    meta: {
      title: 'Contact US | TheMarker'
    }
  },
  {
    path: '/data/drug',
    name: 'data-drug',
    component: () => import(/* webpackChunkName: "about" */ '../views/DataDrug.vue'),
    meta: {
      title: 'Drug Information | TheMarker'
    }
  },
  {
    path: '/data/disease',
    name: 'data-disease',
    component: () => import(/* webpackChunkName: "about" */ '../views/DataDisease.vue'),
    meta: {
      title: 'Disease Information | TheMarker'
    }
  },
  {
    path: '/data/marker',
    name: 'data-marker',
    component: () => import(/* webpackChunkName: "about" */ '../views/DataMarker.vue'),
    meta: {
      title: 'Biomarker Information | TheMarker'
    }
  },
  {
    path: '/search-disease-page',
    name: 'search-disease-page',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchDiseasePage.vue'),
    meta: {
      title: 'Search for Disease | TheMarker'
    }
  },
  {
    path: '/search-drug-page',
    name: 'search-drug-page',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchDrugPage.vue'),
    meta: {
      title: 'Search for Drug | TheMarker'
    }
  },
  {
    path: '/search-marker-page',
    name: 'search-marker-page',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchBiomarkerPage.vue'),
    meta: {
      title: 'Search for Biomarker | TheMarker'
    }
  },



  {
    path: '/search-drug',
    name: 'search-drug',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchDrug.vue'),
    meta: {
      title: 'Drug Search Results | TheMarker'
    }
  },
  {
    path: '/search-disease',
    name: 'search-disease',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchDisease.vue'),
    meta: {
      title: 'Disease Search Results | TheMarker'
    }
  },
  {
    path: '/search-marker',
    name: 'search-marker',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchBiomarker.vue'),
    meta: {
      title: 'Biomarker Search Results | TheMarker'
    }
  },

  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutPage.vue'),
    meta: {
      title: 'About | TheMarker'
    }
  },
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "about" */ '../views/DownloadPage.vue'),
    meta: {
      title: 'Full Data Download | TheMarker'
    }
  },
  {
    path: '/browse',
    name: 'browse',
    component: () => import(/* webpackChunkName: "about" */ '../views/BrowsePage.vue'),
    meta: {
      title: 'Browse Biomarkers | TheMarker'
    }
  },

  {
    path: '/manual',
    name: 'manual',
    component: () => import(/* webpackChunkName: "about" */ '../views/ManualPage.vue'),
    meta: {
      title: 'Manual | TheMarker'
    }
  },
  {
    path: '/citation',
    name: 'citation',
    component: () => import(/* webpackChunkName: "about" */ '../views/CitationPage.vue'),
    meta: {
      title: 'Citation Guidelines for TheMarker | TheMarker'
    }
  },
  {
    path: '/to-be-available',
    name: 'to-be-available',
    component: () => import(/* webpackChunkName: "about" */ '../views/ToBeAvailable.vue'),
    meta: {
      title: 'To be available | TheMarker'
    }
  },
  // {
  //   path: '/test',
  //   name: 'test',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/TestDemo.vue'),
  //   meta: {
  //     title: 'test | TheMarker'
  //   }
  // },
  // {
  //   path: '/test2',
  //   name: 'test2',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/TestDemo2.vue'),
  //   meta: {
  //     title: 'test2 | TheMarker'
  //   }
  // },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/ToBeAvailable.vue')
  // },


  // {
  //   path: '/graph/wordcloud',
  //   name: 'wordcloud',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/WordCloud.vue')
  // },
  // {
  //   path: '/graph/sunburst',
  //   name: 'sunburst',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/SunBurst.vue')
  // },
  // {
  //   path: '/disease-class-analysis',
  //   name: 'DiseaseClassAnalysis',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/DiseaseClassAnalysis.vue')
  // },
  // {
  //   path: '/drug-modality-analysis',
  //   name: 'DrugModalityAnalysis',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/DrugModalityAnalysis.vue')
  // },
  // {
  //   path: '/drug-timline',
  //   name: 'drug-timline',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/DrugTimline.vue')
  // },




  // {
  //   path: '/statistics',
  //   name: 'statistics',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/StatisticsPage.vue')
  // },









]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
