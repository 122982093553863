import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
import 'font-awesome/css/font-awesome.min.css'
import '@/assets/style.css'
import '@/assets/iconfont/iconfont.css'
import axios from 'axios';
// import * as echarts from "echarts"

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
      document.title = to.meta.title
  }
  next()
})


// import { v4 as uuidv4 } from 'uuid'
// 挂载到vue原型链上
Vue.prototype.axios = axios;
Vue.config.productionTip = false;

Vue.use(ElementUI,{locale})
// Vue.use(uuidv4)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
