<template>
  <div class="main-width">
    <div
      style="
        display: flex;
        justify-content: space-around;
        width: 85%;
        margin: 0 auto;
        position: relative;
        top: 188px;
      "
    >
      <div
        @click="activeName = 'biomarker'"
        :class="[
          'tag-normal',
          'tag-biomarker',
          activeName == 'biomarker' ? 'active' : 'deactive',
        ]"
      >
        <div class="tag-icon">
          <i class="icon iconfont icon-biomarker" style="font-size: 37px"></i>
        </div>
        <div class="tag-title">Biomarker</div>
        <div class="tag-icon-hover">
          <i class="fa fa-hand-o-up" aria-hidden="true"></i>
        </div>
      </div>
      <div
        @click="activeName = 'drug'"
        :class="[
          'tag-normal',
          'tag-drug',
          activeName == 'drug' ? 'active' : 'deactive',
        ]"
      >
        <div class="tag-icon">
          <i
            class="icon iconfont icon-menzhenxiyao"
            style="transform: scale(1.3)"
          ></i>
        </div>
        <div class="tag-title">Drug</div>
        <div class="tag-icon-hover">
          <i class="fa fa-hand-o-up" aria-hidden="true"></i>
        </div>
      </div>
      <div
        @click="activeName = 'disease'"
        :class="[
          'tag-normal',
          'tag-disease',
          activeName == 'disease' ? 'active' : 'deactive',
        ]"
      >
        <div class="tag-icon">
          <i
            class="icon iconfont icon-disease"
            style="font-size: 37px; transform: scale(1.5)"
          ></i>
        </div>
        <div class="tag-title">Disease</div>
        <div class="tag-icon-hover">
          <i class="fa fa-hand-o-up" aria-hidden="true"></i>
        </div>
      </div>
    </div>

    <div v-show="activeName == 'biomarker'" style="margin-top: -85px">
      <HomeSearchBiomarker></HomeSearchBiomarker>
    </div>

    <div v-show="activeName == 'drug'" style="margin-top: -85px">
      <HomeSearchDrug></HomeSearchDrug>
    </div>

    <div v-show="activeName == 'disease'" style="margin-top: -85px">
      <HomeSearchDisease></HomeSearchDisease>
    </div>

    <div class="bioClass-part">
      <p style="font-weight: bold; margin-bottom: 20px">
        The latest version of TheMarker contained:
      </p>
      <p style="margin-bottom: 20px">
        <img
          :src="`${localUrl}/files/home-picture/bc1.png`"
          style="width: 40px; margin-bottom: -14px"
        />
        218 <i>pharmacodynamic biomarkers</i> indicating the clinical efficacies
        of 1,636 approved, clinical trial, preclinical drugs that cover 115
        molecular classes (such as: AChE inhibitors, MetAP2 inhibitors, and LPA1
        antagonists) for the treatment of 112 disease classes defined by the WHO
        ICD-11 (such as Alzheimer disease, obesity and systemic sclerosis).
      </p>

      <p style="margin-bottom: 20px">
        <img
          :src="`${localUrl}/files/home-picture/bc3.png`"
          style="width: 40px; margin-bottom: -14px"
        />
        624 <i>safety biomarkers</i> that monitor the clinical toxicities
        (gastrointestinal, hepatic, hematological, etc.) of 271 drugs treating
        107 disease classes (Parkinson, seizure, thrombocytopenia, etc.).
      </p>

      <p style="margin-bottom: 20px">
        <img
          :src="`${localUrl}/files/home-picture/bc2.png`"
          style="width: 40px; margin-bottom: -14px"
        />
        104 <i>monitoring biomarkers</i> that facilitate the patient recruitment
        processes for 60 drugs treating 33 disease classes (such as asthma,
        hemophilia and melanoma).
      </p>
      <p style="margin-bottom: 20px">
        <img
          :src="`${localUrl}/files/home-picture/bc4.png`"
          style="width: 40px; margin-bottom: -14px"
        />
        15,893 <i>predictive biomarkers</i> guiding the clinical managements of
        352 drugs for 95 disease classes (such as hepatitis and
        hypercholesterolemia).
      </p>
      <p style="margin-bottom: 20px">
        <img
          :src="`${localUrl}/files/home-picture/bc5.png`"
          style="width: 40px; margin-bottom: -14px"
        />
        103 <i>surrogate endpoints</i> that denoting the clinical outcome for
        435 approved drugs (including 193 accelerated approvals) for the
        treatment of 102 disease classes (tuberculosis, etc.).
      </p>
    </div>
    <!-- 
    <div>
      <div
        style="
          box-shadow: 0 0 2px 0px #7e7c7c;
          border: 1px dashed #b7b7b7;
          margin-top: 40px;
          padding: 50px;
          min-height: 1000px;
        "
      >
        <img
          :src="`${localUrl}/files/home-picture/TheMarker-Figure.png`"
          style="display: initial; width: 100%"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import HomeSearchBiomarker from "@/components/home/HomeSearchBiomarker.vue";
import HomeSearchDrug from "@/components/home/HomeSearchDrug.vue";
import HomeSearchDisease from "@/components/home/HomeSearchDisease.vue";
// import DivUnit from "@/components/DivUnit.vue";
// import * as echarts from "echarts";
// import api from "../api/index";
import pathUrl from "../api/path";
export default {
  name: "HomeView",
  data() {
    return {
      activeName: "biomarker",
      localUrl: pathUrl.localUrl,

      dialogVisible: false,
      // dis_options: [],
      dis_value: ["Disease Class", "All disease"],
      dis_value_show: "All disease",

      checkDrugStatistics1Data: true,
    };
  },
  components: {
    // DivUnit,
    HomeSearchBiomarker,
    HomeSearchDrug,
    HomeSearchDisease,
  },

  methods: {},
  beforeMount() {
    // api.getDiseaseDrugtypeClass().then((res) => {
    //   this.dis_options = res.data.result;
    //   // console.log(res.data);
    // });
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
@select-disease-color: #f1f1f1;

.tag-icon {
  transition: All 0.4s ease-in-out;
  -webkit-transition: All 0.4s ease-in-out;
  -moz-transition: All 0.4s ease-in-out;
  -o-transition: All 0.4s ease-in-out;
}

.tag-normal:hover {
  .tag-icon {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
  }
}

.tag-normal {
  display: flex;
  height: 60px;

  cursor: pointer;
  border-radius: 30px;
  .tag-icon {
    margin: auto 0;
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 25px;
    margin-left: 6px;
    display: flex;
  }
  .tag-icon-hover {
    margin-right: 18px;
    display: flex;
    .fa {
      font-size: 27px;
      margin: auto;
    }
  }
  .tag-title {
    margin: auto;
    font-size: 24px;
    padding-left: 18px;
    padding-right: 24px;
    width: 120px;
    text-align: center;
    font-weight: bold;
    // font-style: italic;
  }
  .icon {
    margin: auto;
    font-size: 27px;
  }
}
.tag-biomarker {
  background: #ee7700;
  border: 2px solid #ee7700;
  color: #ee7700;
  .icon {
    color: #ee7700;
  }
}
.tag-drug {
  background: #2e75b6;
  border: 2px solid #2e75b6;
  color: #2e75b6;
  // border: 2px solid #2e75b6;
  //   background: #2e75b63b;
  .icon {
    color: #2e75b6;
  }
}

.tag-disease {
  background: #d36157;
  border: 2px solid #d36157;
  color: #d36157;
  .icon {
    color: #d36157;
  }
}
.deactive {
  background: #eeeeee;
}
.active {
  color: #fff;
}
.new-font {
  font-family: "ZabriskieInternational Light Regular";

  text-align: center;
  font-size: 40px;
  margin: -15px 0 -15px 0;
  color: #ee7700;
  font-weight: bold;
}

.home-interpretation {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  box-shadow: 4px 4px 4px 5px #e4e8f5;
}
/deep/.el-tabs__header {
  top: 271px;
}

/deep/.el-tabs__nav-wrap::after {
  background-color: transparent;
}
</style>
